<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-card class="card-shadow border-radius-xl mt-6">
      <v-card-text class="px-0 py-0">
        <v-data-table
          v-model="selectedData"
          :headers="headers"
          :items="purchaseDetails"
          item-key="id"
          mobile-breakpoint="0"
          :footer-props="{
            'items-per-page-options': [100, 200, 500, -1],
          }"
          fixed-header
          class="table"
        >
          <template v-slot:top>
            <v-toolbar flat
              ><v-row
                ><v-col cols="3">
                  <v-menu
                    ref="mnu_date"
                    v-model="mnu_date"
                    :close-on-content-click="false"
                    :return-value.sync="mnu_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details="auto"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          input-icon
                          mt-5
                        "
                        dense
                        flat
                        filled
                        solo
                        height="43"
                        placeholder="Date"
                        persistent-hint
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="green lighten-1"
                      header-color="primary"
                      v-model="date"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="mnu_date = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.mnu_date.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col></v-row
              >
              <v-spacer> </v-spacer>
              <v-btn
                @click="save"
                elevation="0"
                :ripple="false"
                height="43"
                dark
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls btn-primary
                  bg-success
                  py-3
                  px-6
                "
                >Save
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.received_no_of_packages`]="{ item }">
            <div>
              <v-text-field
                v-model.number="item.received_no_of_packages"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  mt-5
                "
                dense
                flat
                filled
                solo
                :rules="[
                  (v) => v >= 0 || 'Min should be above 0',
                  (v) =>
                    v <= item.ordered_no_of_packages ||
                    `Max should not be above ${item.ordered_no_of_packages}`,
                ]"
                min="0"
              ></v-text-field>
            </div> </template
          ><template v-slot:[`item.received_quantity`]="{ item }">
            <div>
              <v-text-field
                v-model="item.received_quantity"
                v-on="
                  (item.received_quantity =
                    item.received_no_of_packages * item.unit_weight)
                "
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  mt-5
                "
                dense
                flat
                filled
                solo
                min="0"
              ></v-text-field>
            </div>
          </template>

          <template v-slot:[`item.bay`]="{ item }">
            <div>
              <v-text-field
                v-model="item.bay"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  mt-5
                "
                dense
                flat
                filled
                solo
                :rules="[
                  (v) =>
                    item.received_quantity == 0 || !!v || 'Please Enter Bay',
                ]"
              ></v-text-field>
            </div>
          </template>
        </v-data-table> </v-card-text
      ><v-card-actions>
        <v-row>
          <v-col offset-md="5">
            <v-btn
              @click="openSave"
              elevation="0"
              :ripple="false"
              height="43"
              dark
              class="
                font-weight-bold
                text-capitalize
                btn-ls btn-primary
                bg-success
                py-3
                px-6
              "
              >Save
            </v-btn></v-col
          >
        </v-row>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialogSave" max-width="500px" persistent>
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Do you want to Save</v-card-title
        >
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDailogSave"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6"
            >No</v-btn
          >

          <v-btn
            @click="save"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-success
              py-3
              px-6
            "
            >Yes</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "../api";
export default {
  created() {
    this.viewDtails();
  },

  data() {
    return {
      dialogSave: false,
      id: this.$route.params.id,
      purchaseType: this.$route.params.purchase_type,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_date: false,
      headers: [
        {
          text: "Batch No",
          value: "batch_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Grade",
          value: "grade",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Invoice No",
          value: "invoice_number",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },

        {
          text: "Item Name",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Mark Name",
          value: "mark_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },

        {
          text: "Unit Weight",
          value: "unit_weight",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
          sortable: false,
        },

        {
          text: "Ordered (Packages)",
          value: "ordered_no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
          sortable: false,
        },
        {
          text: "Recieved (Packages)",
          value: "received_no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
          sortable: false,
        },

        {
          text: "Order Quantity (KGs)",
          value: "ordered_quantity",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
          sortable: false,
        },
        {
          text: "Recieved Quantity (KGs)",
          value: "received_quantity",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
          sortable: false,
        },
        {
          text: "Bay",
          value: "bay",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
          sortable: false,
        },
      ],
      purchaseDetails: [],
      selectedData: [],
      overlay: false,
    };
  },
  methods: {
    async viewDtails() {
      let result = await api.getPurchasedetailsForUpdateDispatch(this.id);

      this.purchaseDetails = result;
      this.selectedData = this.purchaseDetails;
    },
    openSave() {
      this.dialogSave = true;
    },
    async save() {
      this.overlay = true;
      this.dialogSave = false;
      let req = {
        child: this.selectedData,
        dispatch_master_id: this.id,
        purchase_type: this.purchaseType,

        delivery_date: this.date,
      };

      try {
        await api.update(req);
        this.overlay = false;
        this.$router.push({ name: "History" });

        // this.dialogClosePO = true;
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },
  },
};
</script>

<style></style>
